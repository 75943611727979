import {createObjectByCalculationData, goEPayOgpo, goEPayPaymentPage, payWithSavedCardById} from "../services/payment";

export default {
    methods: {
      async getPaymentAuth (calculationId) {
        const { data } = await window.axios.post(`insurance-products/auth/${calculationId}`);
        return data;
      },
      async goToPaymentPage(calculationInfo, user) {
          const loader = this.$loading.show({});

          let data = createObjectByCalculationData(calculationInfo, user);

          await goEPayPaymentPage(calculationInfo.auth, calculationInfo.uniqRef, calculationInfo.amount, data);

          loader.hide();
      },

      async payWithSavedCardById(cardId, calculationInfo, user) {
          const loader = this.$loading.show({});

          let data = createObjectByCalculationData(calculationInfo, user)

          await payWithSavedCardById(cardId, calculationInfo.foreignId, calculationInfo.amount, calculationInfo.auth, data)

          loader.hide();
      },

      async ogpoPayment(auth, invoiceId, amount, user) {
        let paymentObject = {
          invoiceId: invoiceId,
          backLink: `${location.origin}/insurance-products/payment/success?invoiceId=${invoiceId}`,
          failureBackLink: `${location.origin}/insurance-products/payment/fail`,
          postLink: `https://797.polisonline.kz/api/setPolicy`,
          failurePostLink: `https://797.polisonline.kz/ogpo?InvoiceID=${invoiceId}`,
          language: "RU",
          description: "Оплата в интернет магазине",
          accountId: "polisonline",
          terminal: '1c29db1d-a172-4484-926d-1dac546487a5',
          amount: amount,
          currency: "KZT",
          phone: "7"+user.phone_number,
          email: user.email,
          cardSave: true,
          auth,
          homebankToken: this.$store.getters['homebankToken']
        };

        const loader = this.$loading.show({});

        await goEPayOgpo(paymentObject);

        loader.hide();
      },

      async freePayment(invoice, user) {
        let paymentObject = {
          invoiceId: invoice.uniqRef,
          backLink: `${location.origin}/insurance-products/${invoice.calculationId}/payment/success?invoiceId=${invoice.id}`,
          failureBackLink: `${location.origin}/insurance-products/${invoice.calculationId}/payment/fail`,
          postLink: invoice.postLink,
          failurePostLink: invoice.failurePostLink,
          language: "RU",
          description: "Свободная оплата ID:" + invoice.policyID,
          terminal: invoice.terminal,
          accountId: user.id,
          amount: invoice.amount,
          currency: "KZT",
          phone: "7"+user.phone_number,
          email: user.email,
          cardSave: true,
          auth: invoice.auth,
          homebankToken: this.$store.getters['homebankToken']
        };

        const loader = this.$loading.show({});

        await goEPayOgpo(paymentObject);

        loader.hide();
      }
    }
}
