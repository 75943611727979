import halyk from './halyk'
import axios from "axios";
import store from "../store";

let axiosInstance = axios.create({
    baseURL: 'https://testepay.homebank.kz/api',
});

export function createObjectByCalculationData(calculationData, user) {
    return {
        postLink: calculationData.postLink,
        failurePostLink: calculationData.failurePostLink,
        terminal: calculationData.terminal,
        phone: "7"+user.phone_number,
        email: user.email,
        calculationId: calculationData?.calculationId || calculationData.id,
        accountId: user.id,
        auth: calculationData.auth,
        homebankToken: store.getters.homebankToken
    }
}

export function createHalykPaymentObject(auth, invoiceId, amount, data) {
    let {terminal, postLink, failurePostLink, phone, email, calculationId, accountId} = data

    let paymentObject = {
        invoiceId: invoiceId,
        backLink: `${location.origin}/insurance-products/${calculationId}/payment/success?invoiceId=${invoiceId}`,
        failureBackLink: `${location.origin}/insurance-products/${calculationId}/payment/fail`,
        postLink: postLink,
        failurePostLink: failurePostLink,
        language: "RU",
        description: "Оплата в интернет магазине",
        accountId: accountId | null,
        terminal: terminal,
        amount: amount,
        currency: "KZT",
        phone: phone,
        email: email,
        cardSave: true,
        homebankToken: store.getters.homebankToken
    };
    paymentObject.auth = auth;

    console.log(JSON.stringify(paymentObject))

    return paymentObject;
}



export function createSavedCardPaymentObject(cardId, invoiceId, amount, data) {
    let {terminal, postLink, failurePostLink, phone, email, calculationId, accountId, auth } = data

    return {
        amount: amount,
        currency: "KZT",
        name: "TEST TEST",
        terminalId: terminal,
        invoiceId: String(invoiceId),
        description: "test",
        accountId: String(accountId),
        email: email,
        phone: phone,
        backLink: `${location.origin}/insurance-products/${calculationId}/payment/success?invoiceId=${invoiceId}`,
        failureBackLink: `${location.origin}/insurance-products/${calculationId}/payment/fail`,
        postLink: postLink,
        failurePostLink: failurePostLink,
        language: "rus",
        token: (auth.access_token || null),
        paymentType: "cardId",
        cardId: {
            id: cardId
        }
    }
}

export function goEPayPaymentPage(auth, invoiceId, amount, data) {
    console.log(auth)
    halyk.pay(createHalykPaymentObject(auth, invoiceId, amount, data))
}

export function goEPayOgpo(paymentObject) {
  halyk.pay(paymentObject)
}

export function payWithSavedCardById(cardId, invoiceId, amount, auth, data) {
    let url = "/payments/cards/auth";

    let paymentObject = createSavedCardPaymentObject(cardId, invoiceId, amount, data)

    let options = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + auth.access_token,
        },
        data: paymentObject,
        url
    }

    return axiosInstance(options)
}


